import React from 'react';
import {
  Grid, ButtonBase, Paper, useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { CustomTypography } from '../CustomComponents';
import { getSx } from './sx';
import { DashboardCardProps } from './dashboard-card';

const DashboardCard: React.FC<DashboardCardProps> = ({
  id,
  path,
  label,
  icon: Icon,
  color,
  subLabel,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Grid item xs={12} md={4} lg={3} key={id}>
      <ButtonBase
        focusRipple
        onClick={() => {
          navigate(path);
        }}
        sx={{
          width: '100%',
        }}
      >
        <Paper sx={getSx(color, theme).paper}>
          {Icon && (
          <Box sx={{ flex: 1 }}>
            <Icon style={{ fontSize: 40 }} />
          </Box>
          )}
          <CustomTypography color="inherit" variant="h4" title={label} />
          <CustomTypography variant="h4" title={subLabel} />
        </Paper>
      </ButtonBase>
    </Grid>
  );
};

export default DashboardCard;

import React from 'react';
import {
  Button, Divider, IconButton, CircularProgress,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CustomTypography from '../CustomTypography';
import { IProps } from './custom-button';
import { getButtonSx, getTypoSx } from './sx';
import { getButtonVariant } from './utils';

const CustomButton = React.forwardRef(
  (
    {
      title = '',
      handler = null,
      variant = 'contained',
      color = 'primary',
      size = 'medium',
      disabled = false,
      type = 'button',
      fullWidth = false,
      endIcon = null,
      startIcon: StartIcon = null,
      iconButton = false,
      icon: Icon = null,
      buttonProps = {},
      underline = false,
      customIcon = null,
      sx = {},
      isLoading = false,
      bigButton = false,
      href = undefined,
      typographySx = {},
    }: IProps,
    ref,
  ) => {
    const theme = useTheme();
    const buttonStyleSx = getButtonSx(theme);
    const typoStyleSx = getTypoSx();

    return (
      <>
        {!iconButton && (
          <Button
            ref={ref}
            variant={getButtonVariant(variant)}
            size={size}
            disabled={disabled || isLoading}
            color={color}
            onClick={handler}
            type={type}
            fullWidth={fullWidth}
            href={href}
            target={href ? '_blank' : undefined}
            sx={{
              backgroundColor: theme.palette.customGreen.main,
              borderRadius: '8px',
              height: '46px',
              margin: '0px 6px',
              padding: bigButton ? theme.spacing(2, 3) : theme.spacing(1, 2),
              justifyContent: endIcon ? 'space-between' : 'center',
              width: bigButton ? '100%' : undefined,
              ...buttonStyleSx[variant],
              ...sx,
            }}
            startIcon={StartIcon}
            endIcon={isLoading ? <CircularProgress size={20} /> : endIcon}
            {...buttonProps}
          >
            <CustomTypography
              title={title}
              isBold
              align={endIcon ? 'left' : 'center'}
              sx={{
                fontSize: '16px',
                lineHeight: '20px',
                letterSpacing: '0.4px',
                fontWeight: 'bold',
                textTransform: 'none',
                ...typoStyleSx[variant],
                ...typographySx,
              }}
            />
            {underline && (
              <Divider
                sx={{
                  borderBottom: '1px solid',
                  minWidth: '63%',
                  position: 'absolute',
                  left: 25,
                }}
              />
            )}
          </Button>
        )}
        {iconButton && (
          <IconButton
            ref={ref}
            onClick={handler}
            component="span"
            disabled={disabled}
            {...buttonProps}
            style={disabled ? { color: 'rgba(0, 0, 0, 0.26)' } : {}}
            color={color}
          >
            {!customIcon ? Icon : customIcon}
          </IconButton>
        )}
      </>
    );
  },
);

// help for debugging - the console will show this name
CustomButton.displayName = 'CustomButton';

export default CustomButton;

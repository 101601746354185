import { RoutesDefinition } from '../routes';
import { MENU_ADMIN_LIST } from '../Lists/admin-menu-list';
import { MENU_PRODUCTION_LIST } from '../Lists/production-menu-list';
import { MENU_GENERAL_LIST } from '../Lists/general-menu-list';

const mainRoutes: RoutesDefinition[] = [
  {
    name: 'Espace Admin',
    authorization: 'ADMIN',
    path: '/admin',
    isLeftDrawer: false,
    isDashBoardCards: true,
    menuList: MENU_ADMIN_LIST,
  },
  {
    name: 'Espace Production',
    authorization: 'PRODUCTION',
    path: '/admin/production',
    isLeftDrawer: false,
    isDashBoardCards: true,
    menuList: MENU_PRODUCTION_LIST,
  },
  {
    name: 'Paramétrage Général',
    authorization: 'GENERAL',
    path: '/admin/general',
    isLeftDrawer: false,
    isDashBoardCards: true,
    menuList: MENU_GENERAL_LIST,
  },
];

export default mainRoutes;

import { createSlice } from '@reduxjs/toolkit';
import { CREATE_SECTION, GET_SECTIONS } from './section.thunk';
import { SectionState } from './section';
import { CLEAN_ALL_STATES } from '../global-actions';

const initialState: SectionState = {
  list: [],
  isLoading: false,
};

export const sectionSlice = createSlice({
  name: 'section',
  initialState,
  reducers: {
    clearSections() {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(CREATE_SECTION.fulfilled, (state, action) => {
      state.list.push(action.payload);
      state.isLoading = false;
    });
    builder.addCase(CREATE_SECTION.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(CREATE_SECTION.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(GET_SECTIONS.fulfilled, (state, action) => ({
      ...state, list: action.payload, isLoading: false,
    }));
    builder.addCase(GET_SECTIONS.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(GET_SECTIONS.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(CLEAN_ALL_STATES, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});

export const SectionsActions = {
  ...sectionSlice.actions,
  CREATE_SECTION,
  GET_SECTIONS,
};

import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Box, Typography, IconButton } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ALL_MENU_LISTS from '../../routes/Lists';
import { MenuStat } from '../../routes/routes';

const Breadcrumbs = () => {
  const location = useLocation();
  const parts = location.pathname.split('/').filter(Boolean);

  const breadcrumbs = parts.map((part, index) => {
    const path = `/${parts.slice(0, index + 1).join('/')}`;
    const menuItem = ALL_MENU_LISTS.find((menu: MenuStat) => menu.path === path);
    if (!menuItem) return null;

    if (index === parts.length - 1) {
      return (
        <Box
          key={path}
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '0.2rem 0.5rem',
            borderRadius: '10px',
            backgroundColor: 'primary.main',
            color: 'primary.contrastText',
          }}
        >
          <menuItem.icon />
          <Typography>
            {menuItem.label}
          </Typography>
        </Box>
      );
    }

    return (
      <Box
        component={RouterLink}
        to={path}
        key={path}
        sx={{
          display: 'flex',
          alignItems: 'center',
          color: 'text.secondary',
          textDecoration: 'none',
          padding: '0.2rem  0.5rem',
          borderRadius: '10px',
          backgroundColor: 'action.hover',
          border: '1px solid',
          borderColor: 'divider',
          '&:hover': {
            backgroundColor: 'action.active',
            color: 'text.primary',
          },
        }}
      >
        <IconButton size="small" sx={{ padding: 0, marginRight: 1 }}>
          <menuItem.icon color="action" />
        </IconButton>
        <Typography color="inherit">
          {menuItem.label}
        </Typography>
        <IconButton size="small" sx={{ padding: 0, marginLeft: 1 }}>
          <NavigateNextIcon fontSize="small" color="action" />
        </IconButton>
      </Box>
    );
  });

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
      {breadcrumbs}
    </Box>
  );
};

export default Breadcrumbs;

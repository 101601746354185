/* eslint-disable import/no-namespace */
import React, { useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import * as weekOfYear from 'dayjs/plugin/weekOfYear';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { Box, CssBaseline } from '@mui/material';
import { Action } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import Snackbar from '../UI/GlobalSnackBar';
import { CHECK_LOG_USER } from '../../store/user/user.thunk';
import { useAppDispatch } from '../../hook/UseReduxHook';
import { saveUser } from '../../store/user/user.slice';
import { CheckedUser } from './app';
import { socket } from '../../config/socket';
import { ActionsActions } from '../../store/action/action.slice';
import { TasksActions } from '../../store/task/task.slice';
import { Task } from '../../store/task/task';
import { IndicatorsActions } from '../../store/indicator/indicator.slice';
import { IndicatorAddOrUpdate } from '../../store/indicator/indicator';
import Routing from '../../routes';
import CustomAppBar from '../CustomAppBar';

dayjs.extend(weekOfYear as any);

const App: React.FC = () => {
  const user = useSelector((state: RootState) => state.user.current);
  const dispatch = useAppDispatch();

  const { addNewAction, updateAction } = ActionsActions;
  const { updateIndicator, addNewIndicator } = IndicatorsActions;
  const { addNewTask, updateTask } = TasksActions;

  const shouldMount = useRef(true);

  useEffect(() => {
    async function checkUser(token: string) {
      if (shouldMount.current) {
        const checkedUser = await dispatch(CHECK_LOG_USER(token));
        if ((checkedUser?.payload as CheckedUser)?.isUser) {
          dispatch(saveUser((checkedUser.payload as CheckedUser).user));
        }
      }
    }

    if (shouldMount.current) {
      const token = localStorage.getItem('auth-token-planitime');
      if (token && !user.id) {
        checkUser(token);
      }
      socket.on('addAction', (action: Action) => {
        dispatch(addNewAction(action));
      });

      socket.on('updateAction', (action: Action) => {
        dispatch(updateAction(action));
      });

      socket.on('addTask', (task: Task) => {
        dispatch(addNewTask(task));
      });

      socket.on('updateTask', (task: Task) => {
        dispatch(updateTask(task));
      });

      socket.on('addIndicator', (indicator: IndicatorAddOrUpdate) => {
        dispatch(addNewIndicator(indicator));
      });

      socket.on('updateIndicator', (indicator: IndicatorAddOrUpdate) => {
        dispatch(updateIndicator(indicator));
      });

      shouldMount.current = false;
    }
  }, []);

  return (
    <>
      <CssBaseline />
      <Snackbar />
      {user?.id && <CustomAppBar />}
      <main>
        <Box sx={{ marginTop: user?.id ? '64px' : '0px' }}>
          <Routing user={user} key={user.id} />
        </Box>
      </main>
    </>
  );
};

export default App;

import TopicIcon from '@mui/icons-material/Topic';
import { MenuStat } from '../routes';

export const MENU_GENERAL_LIST: MenuStat[] = [
  {
    id: 'user',
    path: '/admin/general/user',
    label: 'Gestion utilisateurs',
    subLabel: '',
    icon: TopicIcon,
    color: '',
    autorizations: ['GENERAL_USER'],
  },

];

import { MenuStat, RoutesDefinition } from './routes';
import { User } from '../store/user/user';

export const getUserRoutes = (user: User, routes: RoutesDefinition[]) => routes
  .filter((routeItem) => user.roles.some((roleItem) => roleItem.name === 'PLANITOOLS')
  || user.roles.some((roleItem) => roleItem.name === routeItem.authorization));

export const getUserMenuList = (user: User, menuList: MenuStat[]) => menuList
  .filter((menuItem) => user.roles.some((roleItem) => roleItem.name === 'PLANITOOLS')
  || user.roles.find((role) => menuItem.autorizations.includes(role.name)));

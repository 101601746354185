import React from 'react';
import { TextField, BaseTextFieldProps, Box } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';

interface IProps extends BaseTextFieldProps {
  isFocused? :boolean
  errorMessage ?:string
  control ?:any
  autocomplete ?:string
  error?:any
  noWrapper?:boolean
}

interface nonBaseTextFieldProps {
  variant: 'standard' | 'outlined' | 'filled'
}

const CustomControlledTextField: React.FC<IProps & nonBaseTextFieldProps> = ({
  noWrapper = false,
  size = 'small',
  label = '',
  name = '',
  type = 'string',
  rows = undefined,
  fullWidth = true,
  isFocused = false,
  inputProps = {},
  variant = 'outlined',
  error = null,
  errorMessage = '',
  defaultValue = '',
  disabled = false,
  required = false,
  control = null,
  autocomplete = '',
  sx,
}) => {
  const theme = useTheme();

  return (
    <>
      {!noWrapper && (
      <Box
        sx={{
          margin: theme.spacing(1, 'auto'),
        }}
      >
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, onBlur } }) => (
            <TextField
              required={required}
              autoComplete={autocomplete}
              type={type}
              id={name}
              variant={variant}
              label={label}
              size={size}
              multiline={!!rows}
              rows={rows}
              inputProps={inputProps}
              error={error}
              helperText={errorMessage}
              fullWidth={fullWidth}
              autoFocus={isFocused}
              defaultValue={defaultValue}
              disabled={disabled}
              onChange={(event) => {
                onChange(event);
              }}
              onBlur={onBlur}
              sx={sx}
            />
          )}
        />
      </Box>
      ) }
      {noWrapper
       && (
       <Controller
         name={name}
         control={control}
         render={({ field: { onChange, onBlur } }) => (
           <TextField
             required={required}
             autoComplete={autocomplete}
             type={type}
             id={name}
             variant={variant}
             label={label}
             size={size}
             multiline={!!rows}
             rows={rows}
             inputProps={inputProps}
             error={error}
             helperText={errorMessage}
             fullWidth={fullWidth}
             autoFocus={isFocused}
             defaultValue={defaultValue}
             disabled={disabled}
             onChange={onChange}
             onBlur={onBlur}
           />
         )}
       />
       )}
    </>

  );
};

export default CustomControlledTextField;

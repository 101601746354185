import { createSlice } from '@reduxjs/toolkit';
import { RolesState } from './role';
import { CLEAN_ALL_STATES } from '../global-actions';
import {
  FETCH_ROLES, CREATE_ROLE, ADD_AUTH_TO_ROLE, REMOVE_AUTH_FROM_ROLE,
} from './role.thunk';

const initialState: RolesState = {
  list: [],
  isLoading: false,
};

export const roleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    cleanRoles: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(FETCH_ROLES.fulfilled, (state, action) => {
      state.list = action.payload;
      state.isLoading = false;
    });
    builder.addCase(FETCH_ROLES.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(FETCH_ROLES.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(CREATE_ROLE.fulfilled, (state, action) => {
      state.list.push(action.payload);
      state.isLoading = false;
    });
    builder.addCase(CREATE_ROLE.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(CREATE_ROLE.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(ADD_AUTH_TO_ROLE.fulfilled, (state, action) => {
      state.list = state.list.map((roleItem) => (roleItem.id === action.payload.id ? action.payload : roleItem));
      state.isLoading = false;
    });
    builder.addCase(ADD_AUTH_TO_ROLE.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(ADD_AUTH_TO_ROLE.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(REMOVE_AUTH_FROM_ROLE.fulfilled, (state, action) => {
      state.list = state.list.map((roleItem) => (roleItem.id === action.payload.id ? action.payload : roleItem));
      state.isLoading = false;
    });
    builder.addCase(REMOVE_AUTH_FROM_ROLE.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(REMOVE_AUTH_FROM_ROLE.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(CLEAN_ALL_STATES, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});

export const { cleanRoles } = roleSlice.actions;

export const RoleActions = {
  ...roleSlice.actions,
  FETCH_ROLES,
};

import { createSlice } from '@reduxjs/toolkit';
import { AuthsState } from './auth';
import { CLEAN_ALL_STATES } from '../global-actions';
import { CREATE_AUTH, FETCH_AUTHS, PATCH_AUTH } from './auth.thunk';

const initialState: AuthsState = {
  list: [],
  isLoading: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    cleanAuth: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(FETCH_AUTHS.fulfilled, (state, action) => {
      state.list = action.payload;
      state.isLoading = false;
    });
    builder.addCase(FETCH_AUTHS.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(FETCH_AUTHS.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(CREATE_AUTH.fulfilled, (state, action) => {
      state.list.push(action.payload);
      state.isLoading = false;
    });
    builder.addCase(CREATE_AUTH.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(CREATE_AUTH.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(PATCH_AUTH.fulfilled, (state, action) => {
      state.list = state.list.map((authItem) => (authItem.id === action.payload.id ? action.payload : authItem));
      state.isLoading = false;
    });
    builder.addCase(PATCH_AUTH.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(PATCH_AUTH.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(CLEAN_ALL_STATES, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});

export const { cleanAuth } = authSlice.actions;

export const UserActions = {
  ...authSlice.actions,
  FETCH_AUTHS,
};

import React from 'react';
import {
  Snackbar, Alert, SnackbarProps, AlertProps,
} from '@mui/material';

interface snackbarProps extends SnackbarProps {
  timeout: number;
  display:boolean;
  handleClose:() => void
  message:any
}

interface alertProps extends AlertProps {
}

const SimpleSnackbar : React.FC<snackbarProps & alertProps > = ({
  message = 'erreur',
  color = 'success',
  timeout,
  display,
  handleClose,
}) => (
  <Snackbar
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    open={display}
    autoHideDuration={timeout}
    key={message}
    onClose={handleClose}
  >
    <Alert
      elevation={6}
      variant="filled"
      onClose={handleClose}
      severity={color}
    >
      {message}
    </Alert>
  </Snackbar>
);

export default SimpleSnackbar;

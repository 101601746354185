import TopicIcon from '@mui/icons-material/Topic';
import { MenuStat } from '../routes';

export const MENU_PRODUCTION_LIST: MenuStat[] = [
  {
    id: 'production-data',
    path: '/admin/production/data',
    label: 'Référentiel Production',
    subLabel: '',
    icon: TopicIcon,
    color: '',
    autorizations: ['PRODUCTION_DATA'],
  },
  {
    id: 'production-of',
    path: '/admin/production/production-orders',
    label: 'Ordres de Fabrication',
    subLabel: '',
    icon: TopicIcon,
    color: '',
    autorizations: ['PRODUCTION_OF'],
  },
  {
    id: 'workstations',
    path: '/admin/production/workstation',
    label: 'Poste de travail',
    subLabel: '',
    icon: TopicIcon,
    color: '',
    autorizations: ['OPERATOR'],
  },
];

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { CustomDrawerProps } from './custom-drawer';

const drawerWidth = 180;

const CustomDrawer: React.FC<CustomDrawerProps> = ({ menuList }) => (
  <Drawer
    variant="permanent"
    sx={{
      width: drawerWidth,
      flexShrink: 0,
      '& .MuiDrawer-paper': {
        width: drawerWidth,
        boxSizing: 'border-box',
      },
    }}
    PaperProps={{
      style: {
        marginTop: '64px',
      },
    }}
  >
    <List>
      {menuList.map((menuItem) => (
        <ListItem button key={menuItem.label} component={RouterLink} to={menuItem.path}>
          {menuItem.icon && <ListItemIcon><menuItem.icon /></ListItemIcon>}
          <ListItemText primary={menuItem.label} />
        </ListItem>
      ))}
    </List>
  </Drawer>
);

export default CustomDrawer;

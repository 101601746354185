import { Login } from '../../components/Login';
import { RoutesDefinition } from '../routes';

export const publicRoutes: RoutesDefinition[] = [
  {
    name: 'Connexion',
    component: Login,
    authorization: '',
    path: '/login',
  },
];

import { createSlice } from '@reduxjs/toolkit';
import { GET_INDICATORS } from './indicator.thunk';
import { IndicatorState } from './indicator';
import { CLEAN_ALL_STATES } from '../global-actions';

const initialState: IndicatorState = {
  list: [],
  isLoading: false,
};

export const indicatorSlice = createSlice({
  name: 'indicator',
  initialState,
  reducers: {
    clearIndicators() {
      return {
        ...initialState,
      };
    },
    updateIndicator(state, action) {
      state.list = state.list.map((stateIndicatorItem) => {
        if (stateIndicatorItem._id === action.payload._id) {
          return { ...action.payload };
        }
        return { ...stateIndicatorItem };
      });
    },
    addNewIndicator(state, action) {
      state.list.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GET_INDICATORS.fulfilled, (state, action) => {
      state.list = action.payload;
      state.isLoading = false;
    });
    builder.addCase(GET_INDICATORS.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(GET_INDICATORS.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(CLEAN_ALL_STATES, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});

export const IndicatorsActions = {
  ...indicatorSlice.actions,
  GET_INDICATORS,
};

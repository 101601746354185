import React from 'react';
import {
  TextField, BaseTextFieldProps,
} from '@mui/material';
// import PropTypes from 'prop-types';

interface IProps extends BaseTextFieldProps {
  onBlurField?:Function
  onChangeField?: Function
  inputLabel?:string
  inputValue?:string|number
  inputName:string
  sortid?:string|number
  InputProps?: {}
  value?: string|number
  InputLabelProps?: {}
}

const CustomTextField: React.FC<IProps > = ({
  onBlurField = () => {},
  onChangeField = () => {},
  size = 'small',
  inputLabel = undefined,
  inputValue = '',
  inputName,
  type = 'string',
  multiline = false,
  rows = undefined,
  fullWidth = true,
  inputProps = {},
  InputProps = {},
  InputLabelProps = {},
  variant = 'outlined',
  disabled = false,
  value = '',
  // eslint-disable-next-line no-unused-vars
  sortid = '',
}) => {
  const handleBlur = (evt:any) => {
    onBlurField(evt);
  };

  const handleChange = (evt:any) => {
    onChangeField(evt);
  };

  return (
    <TextField
      disabled={disabled}
      required
      type={type}
      id={inputName}
      variant={variant}
      name={inputName}
      label={inputLabel}
      defaultValue={inputValue}
      size={size}
      multiline={multiline}
      rows={rows}
      value={value}
      InputProps={InputProps}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={inputProps}
      InputLabelProps={InputLabelProps}
      onBlur={handleBlur}
      onChange={handleChange}
      fullWidth={fullWidth}
    />
  );
};

export default CustomTextField;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { UiSlice } from '../ui/ui.slice';
import type { LoginUserDTO, PostUser } from './user';
import axiosApiInstance from '../../config/axios';
import { CheckCredentials } from './user';

const { displayAlert, displayLoader } = UiSlice.actions;

const url: string = process.env.REACT_APP_API_BASE_URI || '/api';
console.log('process.env', process.env);

export const LOG_USER = createAsyncThunk(
  'user/LOG_USER',
  async (payload: LoginUserDTO, thunkApi) => {
    try {
      const { login, password } = payload;
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.post(
        `${url}/auth/login`,
        { login, password },
      );
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: 'Connecté',
          color: 'success',
          timeout: 3000,
        }),
      );
      localStorage.removeItem('auth-token-planitime');
      localStorage.setItem('auth-token-planitime', result.data.accessToken);

      return {
        ...result.data.user,
        token: result.data.accessToken,
      };
    } catch (err: any) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: `${err.message}`,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw err;
    }
  },
);

export const CHECK_LOG_USER = createAsyncThunk(
  'user/CHECK_LOG_USER',
  // eslint-disable-next-line consistent-return
  async (token: string, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.post<CheckCredentials>(`${url}/auth/check-token`, null);
      thunkApi.dispatch(displayLoader(false));
      return {
        ...result.data,
      };
    } catch (err: any) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: `${err.message}`,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw err;
    }
  },
);

export const FETCH_USERS = createAsyncThunk(
  'user/FETCH_USERS',
  async (_, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.get(`${url}/users`);
      thunkApi.dispatch(displayLoader(false));
      return result.data;
    } catch (err: any) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: err.message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw err;
    }
  },
);

export const FETCH_USERS_WITH_PARAMS = createAsyncThunk(
  'user/FETCH_USERS_WITH_PARAMS',
  async (data: {hasOperator?: boolean}, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.post(`${url}/users/filtered`, data);
      thunkApi.dispatch(displayLoader(false));
      return result.data;
    } catch (err: any) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: err.message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw err;
    }
  },
);

export const FETCH_ROLES = createAsyncThunk(
  'user/FETCH_ROLES',
  async (_, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.get(`${url}/users/roles`);
      thunkApi.dispatch(displayLoader(false));
      return result.data;
    } catch (err: any) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: err.message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw err;
    }
  },
);

export const CREATE_USER = createAsyncThunk(
  'user/CREATE_USER',
  async (payload: PostUser, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.post(`${url}/users`, payload);
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: 'Utilisateur enregistré',
          color: 'success',
          timeout: 3000,
        }),
      );
      return result.data;
    } catch (err: any) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: err.message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw err;
    }
  },
);

import {
  Tune as TuneIcon,
} from '@mui/icons-material';
import { MenuStat } from '../routes';

export const MENU_ADMIN_LIST: MenuStat[] = [
  {
    id: 'admin',
    path: '/admin',
    label: 'ADMIN',
    subLabel: '',
    icon: TuneIcon,
    color: '',
    autorizations: ['NONE'], // None does not exist therefore this card won't be seen on screen
  },
  {
    id: 'production',
    path: '/admin/production',
    label: 'PRODUCTION',
    subLabel: '',
    icon: TuneIcon,
    color: '',
    autorizations: ['PRODUCTION'],
  },
  {
    id: 'general',
    path: '/admin/general',
    label: 'PARAMETRAGE GENERAL',
    subLabel: '',
    icon: TuneIcon,
    color: '',
    autorizations: ['PLANITOOLS'],
  },
];

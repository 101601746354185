import { createSlice } from '@reduxjs/toolkit';
import { UsersState } from './user';
import {
  CHECK_LOG_USER, CREATE_USER, FETCH_USERS, FETCH_USERS_WITH_PARAMS, LOG_USER,
} from './user.thunk';
import { CLEAN_ALL_STATES } from '../global-actions';

const initialState: UsersState = {
  list: [],
  isLoading: false,
  current: {
    id: '',
    name: '',
    surname: '',
    login: '',
    roles: [],
  },
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    saveUser: (state: any, action: any) => {
      state.current = { ...action.payload };
    },
    cleanUser: () => {
      localStorage.removeItem('auth-token-planitime');
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(LOG_USER.fulfilled, (state, action) => ({
      ...state, current: action.payload, isLoading: false,
    }));
    builder.addCase(LOG_USER.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(LOG_USER.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(CREATE_USER.fulfilled, (state, action) => {
      state.list.push(action.payload);
      state.isLoading = false;
    });
    builder.addCase(CREATE_USER.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(CREATE_USER.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(CHECK_LOG_USER.fulfilled, (state, action) => ({
      ...state, ...action.payload, isLoading: false, id: action.payload.user.id,
    }));
    builder.addCase(CHECK_LOG_USER.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(CHECK_LOG_USER.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(FETCH_USERS.fulfilled, (state, action) => {
      state.list = action.payload;
      state.isLoading = false;
    });
    builder.addCase(FETCH_USERS.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(FETCH_USERS.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(FETCH_USERS_WITH_PARAMS.fulfilled, (state, action) => {
      state.list = action.payload;
      state.isLoading = false;
    });
    builder.addCase(FETCH_USERS_WITH_PARAMS.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(FETCH_USERS_WITH_PARAMS.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(CLEAN_ALL_STATES, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});

export const { cleanUser, saveUser } = userSlice.actions;

export const UserActions = {
  ...userSlice.actions,
  CHECK_LOG_USER,
  FETCH_USERS,
  LOG_USER,
  CREATE_USER,
};

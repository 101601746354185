import { Theme } from '@mui/material';

export const getSx = (color: string, theme: Theme) => ({
  paper: {
    backgroundColor: color || theme.palette.primary.main,
    padding: '10px',
    color: '#fff',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: '8px',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
});

import { createAsyncThunk } from '@reduxjs/toolkit';
import { UiSlice } from '../ui/ui.slice';
import axiosApiInstance from '../../config/axios';
import { IndicatorPayload } from './indicator';

const { displayAlert, displayLoader } = UiSlice.actions;

const url: string = process.env.REACT_APP_API_BASE_URI!;

export const GET_INDICATORS = createAsyncThunk(
  'indicator/GET_INDICATORS',
  async (payload: IndicatorPayload, thunkApi) => {
    try {
      const result = await axiosApiInstance.get(`${url}/indicators/actions/daily`, { params: payload });
      return result.data;
    } catch (err: any) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: err.message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw err;
    }
  },
);

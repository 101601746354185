/* eslint-disable import/no-cycle */
import { AnyAction, combineReducers, configureStore } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';
import { UiSlice } from './ui/ui.slice';
import { userSlice } from './user/user.slice';
import { operationSlice } from './operation/operation.slice';
import { sectionSlice } from './section/section.slice';
import { taskSlice } from './task/task.slice';
import { actionSlice } from './action/action.slice';
import { indicatorSlice } from './indicator/indicator.slice';
import { operatorSlice } from './operator/operator.slice';
import { roleSlice } from './role/role.slice';
import { authSlice } from './auth/auth.slice';

const combinedReducer = combineReducers({
  ui: UiSlice.reducer,
  user: userSlice.reducer,
  operator: operatorSlice.reducer,
  operation: operationSlice.reducer,
  section: sectionSlice.reducer,
  task: taskSlice.reducer,
  action: actionSlice.reducer,
  indicator: indicatorSlice.reducer,
  auth: authSlice.reducer,
  role: roleSlice.reducer,
});

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === 'user/cleanUser') {
    return combinedReducer(undefined, action);
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunkMiddleware),
});
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { UiSlice } from '../ui/ui.slice';
import axiosApiInstance from '../../config/axios';
import { AuthDto, PatchAuthDto } from './auth';

const { displayAlert, displayLoader } = UiSlice.actions;

const url: string = process.env.REACT_APP_API_BASE_URI!;

export const FETCH_AUTHS = createAsyncThunk(
  'auth/FETCH_AUTHS',
  async (_, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.get(`${url}/auth`);
      thunkApi.dispatch(displayLoader(false));
      return result.data;
    } catch (err: any) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: err.message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw err;
    }
  },
);

export const CREATE_AUTH = createAsyncThunk(
  'auth/CREATE_AUTH',
  async (data: AuthDto, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.post(`${url}/auth`);
      thunkApi.dispatch(displayLoader(false));
      return result.data;
    } catch (err: any) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: err.message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw err;
    }
  },
);

export const PATCH_AUTH = createAsyncThunk(
  'auth/PATCH_AUTH',
  async (data: PatchAuthDto, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.patch(`${url}/auth`);
      thunkApi.dispatch(displayLoader(false));
      return result.data;
    } catch (err: any) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: err.message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw err;
    }
  },
);

import { lazy } from 'react';
import { RoutesDefinition } from '../routes';
import { MENU_PRODUCTION_WORKSTATION_LIST } from '../Lists/production-workstation-list';

const mainRoutes: RoutesDefinition[] = [
  {
    name: 'Espace Opérateur',
    authorization: 'OPERATOR',
    path: '/operator',
    isLeftDrawer: false,
    isDashBoardCards: true,
    menuList: MENU_PRODUCTION_WORKSTATION_LIST,
  },
];

const workstationRoutes = MENU_PRODUCTION_WORKSTATION_LIST.map((workstationItem) => (
  {
    name: `Poste de travail ${workstationItem.id}`,
    component: lazy(() => import('../../components/OperatorInterface/Workstation')),
    authorization: 'OPERATOR',
    path: `/operator/workstation/${workstationItem.id}`,
  }
));

const operatorRoutes = [
  ...mainRoutes,
  ...workstationRoutes,
];

export default operatorRoutes;

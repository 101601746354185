import { createAsyncThunk } from '@reduxjs/toolkit';
import { UiSlice } from '../ui/ui.slice';
import axiosApiInstance from '../../config/axios';
import { Operator } from './operator';

const { displayAlert, displayLoader } = UiSlice.actions;

const url: string = process.env.REACT_APP_API_BASE_URI!;

export const FETCH_OPERATORS = createAsyncThunk(
  'operator/FETCH_OPERATORS',
  async (_, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.get(`${url}/operators`);
      thunkApi.dispatch(displayLoader(false));
      return result.data;
    } catch (err: any) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: err.message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw err;
    }
  },
);

export const CREATE_OPERATOR = createAsyncThunk(
  'operator/CREATE_OPERATOR',
  async (payload: Operator, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.post(`${url}/operators`, payload);
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: 'Opérateur enregistré',
          color: 'success',
          timeout: 3000,
        }),
      );
      return result.data;
    } catch (err: any) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: err.message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw err;
    }
  },
);

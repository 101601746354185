// this static array will be replaced by a request to back side in workstation table

import TopicIcon from '@mui/icons-material/Topic';
import { MenuStat } from '../routes';

export const MENU_PRODUCTION_WORKSTATION_LIST: MenuStat[] = [
  {
    id: '1', // Will be the id of the workstation in database
    path: '/admin/production/workstation/1',
    label: 'Poste de Production 1',
    subLabel: '',
    icon: TopicIcon,
    color: '',
    autorizations: ['OPERATOR'],
  },
  {
    id: '2',
    path: '/admin/production/workstation/2',
    label: 'Poste de Production 2',
    subLabel: '',
    icon: TopicIcon,
    color: '',
    autorizations: ['OPERATOR'],
  },
];

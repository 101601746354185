/* eslint-disable no-lone-blocks */
import React, { useEffect, Suspense } from 'react';
import {
  Routes, Route, Navigate, useNavigate,
} from 'react-router-dom';
import { publicRoutes } from './public/public-routes';
import { RoutesDefinition, RoutingProps } from './routes';
import NotFound from '../components/UI/CustomComponents/NotFound';
import { getUserMenuList, getUserRoutes } from './utils';
import adminRoutes from './admin';
import GenericScreen from '../components/UI/CustomAdminDrawerScreen';
import CustomScreenDashboards from '../components/UI/CustomAdminDashboardScreen';
import operatorRoutes from './operator/main-routes';
import CustomLoader from '../components/UI/CustomLoader';

const Routing: React.FC<RoutingProps> = ({ user }) => {
  const userPrivateRoutes = user && getUserRoutes(user, [...adminRoutes, ...operatorRoutes]);
  const navigate = useNavigate();

  useEffect(() => {
    if (user.id && user.roles.some((roleItem) => roleItem.name === 'ADMIN')) {
      navigate('/admin');
    }
    if (user.id && user.roles.some((roleItem) => roleItem.name === 'OPERATOR')
      && !user.roles.some((roleItem) => roleItem.name === 'ADMIN')) {
      navigate('/operator');
    }
  }, [user]);

  return (
    <Routes>
      {/* CREATING PUBLIC ROUTES */}
      {publicRoutes.map((publicRouteItem: RoutesDefinition) => {
        if (publicRouteItem.component) {
          return (
            <Route
              key={publicRouteItem.path}
              path={publicRouteItem.path}
              element={<publicRouteItem.component />}
            />
          );
        }
        return null;
      })}
      { /* CREATING PRIVATE ROUTES WITH COMPONENT ATTACHED */ }
      {user.id
        && userPrivateRoutes.map((routeItem: RoutesDefinition) => {
          if (routeItem.component) {
            return (
              <Route
                key={routeItem.path}
                path={routeItem.path}
                element={(
                routeItem.isLeftDrawer
                  ? (
                    <GenericScreen menuList={getUserMenuList(user, routeItem.menuList || [])} title={routeItem.name}>
                      <Suspense fallback={<CustomLoader isBackDrop={false} />}>
                        <routeItem.component />
                      </Suspense>
                    </GenericScreen>
                  )
                  : <routeItem.component />
  )}
              />
            );
          }

          { /* CREATING PRIVATE ROUTES WITH "FOLDERS" - render children as cards or in a left drawer menu */ }
          if (!routeItem.component) {
            return (
              <Route
                key={routeItem.path}
                path={routeItem.path}
                element={(
                routeItem.isDashBoardCards
                  ? (
                    <CustomScreenDashboards
                      menuList={getUserMenuList(user, routeItem.menuList || [])}
                      title={routeItem.name}
                    />
                  )
                  : null
  )}
              />
            );
          }
          return null;
        })}

      { /* CREATING PUBLIC Route to login  */ }
      {!user.id && <Route path="*" element={<Navigate to="/login" />} />}

      { /* CREATING a 404 Route */ }
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Routing;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { UiSlice } from '../ui/ui.slice';
import axiosApiInstance from '../../config/axios';
import { RoleDto, PatchAuthDto } from './role';

const { displayAlert, displayLoader } = UiSlice.actions;

const url: string = process.env.REACT_APP_API_BASE_URI!;

export const FETCH_ROLES = createAsyncThunk(
  'role/FETCH_ROLES',
  async (_, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.get(`${url}/roles`);
      thunkApi.dispatch(displayLoader(false));
      return result.data;
    } catch (err: any) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: err.message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw err;
    }
  },
);

export const CREATE_ROLE = createAsyncThunk(
  'role/CREATE_ROLE',
  async (data: RoleDto, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.post(`${url}/roles`, data);
      thunkApi.dispatch(displayLoader(false));
      return result.data;
    } catch (err: any) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: err.message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw err;
    }
  },
);

export const ADD_AUTH_TO_ROLE = createAsyncThunk(
  'role/ADD_AUTH_TO_ROLE',
  async (data: PatchAuthDto, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.patch(`${url}/roles/add-auth`, data);
      thunkApi.dispatch(displayLoader(false));
      return result.data;
    } catch (err: any) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: err.message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw err;
    }
  },
);

export const REMOVE_AUTH_FROM_ROLE = createAsyncThunk(
  'role/REMOVE_AUTH_FROM_ROLE',
  async (data: PatchAuthDto, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.patch(`${url}/roles/remove-auth`, data);
      thunkApi.dispatch(displayLoader(false));
      return result.data;
    } catch (err: any) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: err.message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw err;
    }
  },
);

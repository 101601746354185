import axios, { AxiosError, InternalAxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { ErrorApiModel } from './error-api.model';

const axiosApiInstance = axios.create();

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config: InternalAxiosRequestConfig<any>): Promise<InternalAxiosRequestConfig<any>> => ({
    ...config,
    headers: {
      Authorization: localStorage.getItem('auth-token-planitime')
        ? `Bearer ${localStorage.getItem('auth-token-planitime')}`
        : '',
      Accept: 'application/json',
    } as AxiosRequestHeaders,
  }),
  (error: AxiosError) => Promise.reject(error),
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response) => {
    if (!response?.data) {
      throw new Error('API Error. No data!');
    }
    return response;
  },
  async (error: AxiosError) => {
    if (error.status === 403) {
      return Promise.reject(
        new Error('Token KO'),
      );
    }
    return Promise.reject(new ErrorApiModel(error.response?.data));
  },
);

export default axiosApiInstance;

import { lazy } from 'react';
import { RoutesDefinition } from '../routes';
import { MENU_PRODUCTION_DATA_LIST } from '../Lists/production-data-menu-list';
import { MENU_PRODUCTION_WORKSTATION_LIST } from '../Lists/production-workstation-list';

const productionMainRoutes: RoutesDefinition[] = [
  {
    name: 'Référentiel de Production',
    component: lazy(() => import('../../components/AdminInterface/Production/ProductionData')),
    authorization: 'PRODUCTION_DATA',
    path: '/admin/production/data',
    isLeftDrawer: true,
    menuList: MENU_PRODUCTION_DATA_LIST,
  },
  {
    name: 'Ordres de Fabrication',
    component: lazy(() => import('../../components/AdminInterface/Production/ProductionOrders')),
    authorization: 'PRODUCTION_OF',
    path: '/admin/production/production-orders',
  },
  {
    name: 'Postes de Travail',
    authorization: 'OPERATOR',
    path: '/admin/production/workstation',
    isDashBoardCards: true,
    menuList: MENU_PRODUCTION_WORKSTATION_LIST,
  },
];

const productionDataRoutes: RoutesDefinition[] = [
  {
    name: 'Opérations de Fabrication',
    component: lazy(() => import('../../components/AdminInterface/Production/ProductionData/Operations')),
    authorization: 'PRODUCTION_DATA_OPERATIONS',
    path: '/admin/production/data/operations',
    isLeftDrawer: true,
    menuList: MENU_PRODUCTION_DATA_LIST,
  },
  {
    name: 'Opérateurs',
    component: lazy(() => import('../../components/AdminInterface/Production/ProductionData/Operators')),
    authorization: 'PRODUCTION_DATA_OPERATORS',
    path: '/admin/production/data/operators',
    isLeftDrawer: true,
    isDashBoardCards: false,
    menuList: MENU_PRODUCTION_DATA_LIST,
  },
  {
    name: 'Nomenclatures',
    component: lazy(() => import('../../components/AdminInterface/Production/ProductionData/BOF')),
    authorization: 'PRODUCTION_DATA_BOF', // BOF Bill Of Materials
    path: '/admin/production/data/bof',
    isLeftDrawer: true,
    menuList: MENU_PRODUCTION_DATA_LIST,
  },
];

const workstationRoutes = MENU_PRODUCTION_WORKSTATION_LIST.map((workstationItem) => (
  {
    name: `Poste de travail ${workstationItem.id}`,
    component: lazy(() => import('../../components/OperatorInterface/Workstation')),
    authorization: 'OPERATOR',
    isLeftDrawer: false,
    isDashBoardCards: false,
    path: `/admin/production/workstation/${workstationItem.id}`,
  }
));

const productionRoutes = [
  ...productionMainRoutes,
  ...productionDataRoutes,
  ...workstationRoutes,

];

export default productionRoutes;

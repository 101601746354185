import generalRoutes from './general-routes';
import mainRoutes from './main-routes';
import productionRoutes from './production-routes';

const adminRoutes = [
  ...mainRoutes,
  ...productionRoutes,
  ...generalRoutes,

];

export default adminRoutes;

import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import {
  Paper, Grid,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ChevronRight } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { Box } from '@mui/system';
import { CustomControlledTextField, CustomButton } from '../UI/CustomComponents';
import CustomTypography from '../UI/CustomComponents/CustomTypography';
import { useAppDispatch } from '../../utils/hook/UseReduxHook';
import { LOG_USER } from '../../store/user/user.thunk';
import { AppDispatch, RootState } from '../../store';
import { userSchema } from './formValidation/userSchema';
import { FormValues } from './login';
import logoRoset from '../../assets/logoRoset.JPG';

export const Login = () => {
  const theme = useTheme();
  const dispatch: AppDispatch = useAppDispatch();
  const user = useSelector((state: RootState) => state.user);

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    mode: 'onBlur',
    resolver: yupResolver(userSchema),
  });

  const onSubmit = async (data: FormValues) => {
    dispatch(LOG_USER({ ...data }));
  };
  return (
    <Grid
      container
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Grid
        item
        xs={6}
        sx={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundImage: 'url(/images/background1.jpg)',
          backgroundSize: 'cover',
          color: '#fff',
        }}
      >
        <CustomTypography
          variant="h1"
          isBold
          title="PlaniTrack"
          color="inherit"
          display="block"
        />
        <img
          src="/images/logoPlanitools.jpg"
          alt="logo de la socité Planitools"
          style={{ borderRadius: '4rem' }}
        />
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
          }}
        >
          <Box
            component="div"
            sx={{
              marginBottom: theme.spacing(8),
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <img src={logoRoset} alt="logo of Roset" style={{ width: '30%' }} />
          </Box>

          <Paper
            sx={{
              padding: theme.spacing(4),
              width: '30vw',
            }}
          >
            <CustomTypography
              title="Veuillez vous identifier"
              variant="h4"
              isBold
              color="primary"
            />
            <form noValidate onSubmit={handleSubmit(onSubmit)} id="login-admin">
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <CustomControlledTextField
                    variant="outlined"
                    required
                    id="login"
                    label="Login"
                    autocomplete="login"
                    name="login"
                    control={control}
                    error={!!errors.login}
                    errorMessage={errors.login ? errors.login.message : ''}
                    size="medium"
                    noWrapper
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomControlledTextField
                    variant="outlined"
                    required
                    name="password"
                    label="Mot de passe"
                    autocomplete="new-password"
                    type="password"
                    id="password"
                    control={control}
                    error={!!errors.password}
                    errorMessage={errors.password ? errors.password.message : ''}
                    size="medium"
                    noWrapper
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomButton
                    title="Se connecter"
                    type="submit"
                    fullWidth
                    variant="contained"
                    bigButton
                    isLoading={user.isLoading}
                    endIcon={<ChevronRight />}
                  />
                </Grid>
              </Grid>
            </form>
          </Paper>

        </Box>

      </Grid>

    </Grid>
  );
};

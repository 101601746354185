const initialsCalculation = (fullName: string) => {
  const firstName = fullName.split(' ')[0];
  const surname = fullName.split(' ')[1][0];
  const compounds = firstName.split('-');

  if (compounds.length > 1) {
    return `${compounds[0][0]}${compounds[1][0]}${surname[0][0]}`;
  }
  return `${compounds[0][0]}${surname[0][0]}`;
};

const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export { initialsCalculation, stringToColor };

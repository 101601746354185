import { createAsyncThunk } from '@reduxjs/toolkit';
import { UiSlice } from '../ui/ui.slice';
import axiosApiInstance from '../../config/axios';
import { Nullable } from '../../utils/models/types.utils';

const { displayAlert, displayLoader } = UiSlice.actions;

const url: string = process.env.REACT_APP_API_BASE_URI!;

export const CREATE_ACTION = createAsyncThunk(
  'action/CREATE_ACTION',
  async (payload: any, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.post(`${url}/actions/create`, payload);
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: 'Action enregistrée',
          color: 'success',
          timeout: 3000,
        }),
      );
      return result.data;
    } catch (err: any) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: err.message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw err;
    }
  },
);

export const GET_ACTIONS = createAsyncThunk(
  'action/GET_ACTIONS',
  async (payload: {startDate: Date, endDate?: Nullable<Date> | undefined}, thunkApi) => {
    try {
      const result = await axiosApiInstance.post(`${url}/actions/getActions`, payload);
      return result.data;
    } catch (err: any) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: err.message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw err;
    }
  },
);

export class ErrorApiModel {
  statusCode: string;

  timestamp: string;

  path: string;

  message: string;

  privateMessage: string;

  constructor(data: any) {
    this.message = data.message;
    this.path = data.path;
    this.privateMessage = data.privateMessage;
    this.statusCode = data.statusCode;
    this.timestamp = data.timestamp;
  }
}

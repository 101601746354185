import { connect } from 'react-redux';
import Snackbar from '../Snackbar';
import { UiSlice } from '../../../store/ui/ui.slice';
import { uiDefinition } from '../../../store/ui/ui';

const mapStateToProps:any = (state: uiDefinition) => ({
  message: state.ui.snackbarMessage,
  color: state.ui.snackbarColor,
  timeout: state.ui.snackbarTimeout,
  display: state.ui.snackbarDisplay,
});

const mapDispatchToProps = (dispatch: any) => ({
  handleClose: (event: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(UiSlice.actions.closeAlert());
  },
});

export default connect<any, any, any>(mapStateToProps, mapDispatchToProps)(Snackbar);

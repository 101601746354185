import {
  CssBaseline, AppBar, Toolbar, Typography, Box,
} from '@mui/material';
import React from 'react';
import CustomAvatar from './CustomAvatar';

const CustomAppBar: React.FC = () => (
  <Box sx={{ display: 'flex' }}>
    <CssBaseline />
    <AppBar
      position="fixed"
      sx={{
        height: '64px',
      }}
    >
      <Toolbar>
        <Box
          component="div"
          sx={{
            display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%',
          }}
        >
          <img
            src="/images/logoPlanitools.jpg"
            alt="logo Planitools"
            style={{
              height: 'calc(0.8*64px)',
              borderRadius: '5rem',
            }}
          />
          <Typography
            variant="h3"
            noWrap
            component="div"
            sx={{ color: 'white' }}
          >
            PlaniTrack
          </Typography>
          <CustomAvatar />
        </Box>
      </Toolbar>
    </AppBar>
  </Box>
);

export default CustomAppBar;

import TopicIcon from '@mui/icons-material/Topic';
import { MenuStat } from '../routes';

export const MENU_PRODUCTION_DATA_LIST: MenuStat[] = [
  {
    id: 'production-data-operations',
    path: '/admin/production/data/operations',
    label: 'Opérations',
    subLabel: '',
    icon: TopicIcon,
    color: '',
    autorizations: ['PRODUCTION_DATA_OPERATIONS'],
  },
  {
    id: 'production-data-operators',
    path: '/admin/production/data/operators',
    label: 'Opérateurs',
    subLabel: '',
    icon: TopicIcon,
    color: '',
    autorizations: ['PRODUCTION_DATA_OPERATORS'],
  },
];

import React from 'react';
import { Box, Grid } from '@mui/material';
import { CustomTypography } from '../CustomComponents';
import CustomDrawer from '../CustomDrawer';
import { CustomAdminDrawerScreenProps } from './custom-admin-screen';
import Breadcrumbs from '../../BreadCrumbs';

const CustomAdminDrawerScreen: React.FC<CustomAdminDrawerScreenProps> = ({ menuList, title, children }) => (
  <Grid container>
    <Grid item>
      <CustomDrawer
        menuList={menuList}
      />
    </Grid>
    <Grid item>
      <Box sx={{ padding: '1rem' }}>
        <Breadcrumbs />
        <CustomTypography title={title} variant="h4" />
        {children}
      </Box>
    </Grid>
  </Grid>
);

export default CustomAdminDrawerScreen;

import { MENU_ADMIN_LIST } from './admin-menu-list';
import { MENU_PRODUCTION_LIST } from './production-menu-list';
import { MENU_PRODUCTION_DATA_LIST } from './production-data-menu-list';
import { MENU_GENERAL_LIST } from './general-menu-list';
import { MENU_GENERAL_USER_LIST } from './general-user-menu-list';

const ALL_MENU_LISTS = [
  ...MENU_ADMIN_LIST,
  ...MENU_PRODUCTION_LIST,
  ...MENU_PRODUCTION_DATA_LIST,
  ...MENU_GENERAL_LIST,
  ...MENU_GENERAL_USER_LIST,
];

export default ALL_MENU_LISTS;

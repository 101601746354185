import { createTheme } from '@mui/material/styles';
import { frFR } from '@mui/material/locale';

const theme = createTheme({
  layout: {
    drawer: {
      width: {
        opened: 240,
        closed: 56,
      },
    },
    appBar: {
      zIndex: 10,
    },
    iconButtonWidth: 24,
    scrollbarStyles: {
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: '6px',
        backgroundColor: '#F5F5F5',
      },
      '&::-webkit-scrollbar-track': {
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
        backgroundColor: '#F5F5F5',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#1a237e',
      },
    },
  },
  palette: {
    primary: {
      main: '#5B28E4',
      light: '#230D5D',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#DF0000',
      light: '#DF6800',
    },
    info: {
      main: '#3A2965',
      light: '#4F3D7D',
      contrastText: '#1C1331',
    },
    error: {
      main: '#DF0000',
      light: '#DF6800',
    },
    success: {
      main: '#21C162',
      light: '#00BEDC',
      contrastText: '#8A5FFE',
    },
    warning: {
      main: '#DF6800',
      light: '#4B5B64',
      contrastText: '#FFFFFF',
    },
    pending: {
      main: '#E8C92D',
      light: '#D1D1D1',
    },
    default: {
      main: '#1E3440',
      light: '#D6E0E7',
      contrastText: '#D1D1D1',
    },
    contrastText: '#FFFFFF',
    customYellow: {
      main: '#C3952842',
      light: '#8399A5',
    },
    customBlue: {
      main: '#DFEDF5',
      light: '#9C27B0',
    },
    customGreen: {
      main: '#00BEDC',
      light: '#E0F2F1',
    },
    customGrey: {
      main: '#282ED530',
      light: '#795548',
    },
  },
  typography: {
    fontFamily: [
      'Poppins',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: 12,
    h1: {
      fontSize: '33px',
      lineHeight: '35px',
      fontWeight: 'bold',
      letterSpacing: '0px',
    },
    h2: {
      fontSize: '25px',
      lineHeight: '30px',
      fontWeight: 'bold',
      letterSpacing: '0px',
    },
    h3: {
      fontSize: '22px',
      lineHeight: '33px',
      fontWeight: 'bold',
      letterSpacing: '0.55px',
      textTransform: 'uppercase',
    },
    h4: {
      fontSize: '17px',
      lineHeight: '20px',
      fontWeight: 'bold',
      letterSpacing: '0.85px',
      textTransform: 'uppercase',
    },
    h5: {
      fontSize: '17px',
      lineHeight: '20px',
      fontWeight: 'bold',
      letterSpacing: '0px',
      textTransform: 'uppercase',
    },
    h6: {
      fontSize: '15px',
      lineHeight: '17px',
      fontWeight: 'bold',
      letterSpacing: '0.75px',
      textTransform: 'uppercase',
    },
    subtitle1: {
      fontSize: '12px',
      lineHeight: '20px',
      fontWeight: 'bold',
      letterSpacing: '0.3px',
      textTransform: 'uppercase',
    },
    subtitle2: {
      fontSize: '17px',
      lineHeight: '20px',
      fontWeight: 'normal',
      letterSpacing: '0.42px',
    },
    subtitle3: {
      fontSize: '16px',
      lineHeight: '15px',
      fontWeight: 'bold',
      letterSpacing: '0px',
    },
    subtitle4: {
      fontSize: '16px',
      lineHeight: '20px',
      fontWeight: 'medium',
      letterSpacing: '0px',
    },
    subtitle5: {
      fontSize: '14px',
      lineHeight: '15px',
      fontWeight: 'bold',
      letterSpacing: '0px',
    },
    subtitle6: {
      fontSize: '14px',
      fontWeight: 'medium',
      lineHeight: '20px',
      letterSpacing: '0px',
    },
    button: {
      fontWeight: 600,
      lineHeight: 2.5,
      letterSpacing: '0.02857em',
      textTransform: 'none',
    },
    color: {
      primary: '#1E3440',
      secondary: '#4B5B64',
      hint: '#A3A3A3',
      dark: '#3a2965',
    },
  },
  chip: {
    primary: {
      background: '#E4F8DD',
      text: '#279C1B',
    },
    secondary: {
      background: '#FFF5D7',
      text: '#C39528',
    },
    tertiary: {
      background: '#FFE2E1',
      text: '#BA2A3E',
    },
    fourth: {
      background: '#E1FBFF',
      text: '#00BEDC',
    },
  },
  frFR,
});

export default theme;

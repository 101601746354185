import { createSlice } from '@reduxjs/toolkit';
import { UPLOAD_OPERATIONS, GET_OPERATIONS } from './operation.thunk';
import { OperationState } from './operation';
import { CLEAN_ALL_STATES } from '../global-actions';

const initialState: OperationState = {
  list: [],
  isLoading: false,
};

export const operationSlice = createSlice({
  name: 'operation',
  initialState,
  reducers: {
    clearOperations() {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(UPLOAD_OPERATIONS.fulfilled, (state, action) => {
      const newList = [...state.list, ...action.payload];
      return { ...state, list: newList, isLoading: false };
    });
    builder.addCase(UPLOAD_OPERATIONS.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(UPLOAD_OPERATIONS.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(GET_OPERATIONS.fulfilled, (state, action) => ({
      ...state, list: action.payload, isLoading: false,
    }));
    builder.addCase(GET_OPERATIONS.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(GET_OPERATIONS.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(CLEAN_ALL_STATES, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});

export const OperationActions = {
  ...operationSlice.actions,
  UPLOAD_OPERATIONS,
  GET_OPERATIONS,
};

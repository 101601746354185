import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { UiSlice } from '../ui/ui.slice';
import axiosApiInstance from '../../config/axios';
import { Nullable } from '../../utils/models/types.utils';

const { displayAlert, displayLoader } = UiSlice.actions;

const url: string = process.env.REACT_APP_API_BASE_URI!;

export const UPLOAD_OPERATIONS = createAsyncThunk(
  'operation/UPLOAD_OPERATIONS',
  async (payload: any, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axios.post(`${url}/operations`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: localStorage.getItem('auth-token-planitime')
            ? `Bearer ${localStorage.getItem('auth-token-planitime')}`
            : '',
        },
      });
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: `${result.data.length} opérations enregistrées`,
          color: 'success',
          timeout: 3000,
        }),
      );
      return result.data;
    } catch (err: any) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: err.message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw err;
    }
  },
);

export const GET_OPERATIONS = createAsyncThunk(
  'operation/GET_OPERATIONS',
  async (payload: {creationDate?: Nullable<Date> | undefined}, thunkApi) => {
    try {
      const result = await axiosApiInstance.post(`${url}/operations/getOperations`, payload);
      return result.data;
    } catch (err: any) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: err.message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw err;
    }
  },
);

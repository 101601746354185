import React from 'react';
import { Box, Grid } from '@mui/material';
import { RootState } from '../../../store';
import CustomTypography from '../CustomComponents/CustomTypography';
import DashboardCard from '../DashboardCard';
import { useAppSelector } from '../../../utils/hook/UseReduxHook';
import { CustomAdminDashboardScreenProps } from './custom-screen-dashboard';
import Breadcrumbs from '../../BreadCrumbs';

const CustomAdminDashboardScreen: React.FC<CustomAdminDashboardScreenProps> = ({
  title,
  menuList,
}) => {
  const user = useAppSelector(((state: RootState) => state.user));

  return (
    <Grid item xs={12} md={4} lg={3} margin={4}>
      <Box sx={{ padding: '1rem 0' }}>
        <Breadcrumbs />
      </Box>
      <Grid container>
        <Grid item xs={6}>
          <CustomTypography
            variant="h4"
            title={`${title} ${user.current.name}`}
            align="left"
          />
        </Grid>
        <Grid container spacing={2}>
          {menuList.map((props) => (
            <DashboardCard key={props.id} {...props} />
          ))}
        </Grid>
      </Grid>

    </Grid>
  );
};

export default CustomAdminDashboardScreen;

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  CREATE_OPERATOR, FETCH_OPERATORS,
} from './operator.thunk';
import { CHECK_LOG_USER, LOG_USER } from '../user/user.thunk';
import { OperatorsState } from './operator';
import { CLEAN_ALL_STATES } from '../global-actions';
import { CheckCredentials } from '../user/user';

const initialState: OperatorsState = {
  list: [],
  isLoading: false,
  current: {
    id: '',
    name: '',
    surname: '',
    user: '',
  },
};

export const operatorSlice = createSlice({
  name: 'operator',
  initialState,
  reducers: {
    saveOperator: (state, action) => {
      state.current = { ...action.payload };
    },
    cleanOperator: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(CREATE_OPERATOR.fulfilled, (state, action) => {
      state.list.push(action.payload);
      state.isLoading = false;
    });
    builder.addCase(CREATE_OPERATOR.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(CREATE_OPERATOR.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(FETCH_OPERATORS.fulfilled, (state, action) => {
      state.list = action.payload;
      state.isLoading = false;
    });
    builder.addCase(FETCH_OPERATORS.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(FETCH_OPERATORS.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(LOG_USER.fulfilled, (state, action) => {
      if (action.payload.operator) {
        return {
          ...state, current: action.payload.operator,
        };
      }
      return { ...state };
    });
    builder.addCase(CHECK_LOG_USER.fulfilled, (state, action: PayloadAction<CheckCredentials>) => {
      if (action?.payload?.user?.operator) {
        return {
          ...state, current: action?.payload?.user?.operator,
        };
      }
      return { ...state };
    });
    builder.addCase(CLEAN_ALL_STATES, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});

export const OperatorActions = {
  ...operatorSlice.actions,
  CREATE_OPERATOR,
  FETCH_OPERATORS,
};

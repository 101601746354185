import TopicIcon from '@mui/icons-material/Topic';
import { MenuStat } from '../routes';

export const MENU_GENERAL_USER_LIST: MenuStat[] = [
  {
    id: 'general-user-users',
    path: '/admin/general/user/users',
    label: 'Utilisateurs',
    subLabel: '',
    icon: TopicIcon,
    color: '',
    autorizations: ['GENERAL_USER_USERS'],
  },
  {
    id: 'general-user-roles',
    path: '/admin/general/user/roles',
    label: 'Rôles',
    subLabel: '',
    icon: TopicIcon,
    color: '',
    autorizations: ['GENERAL_USER_ROLES'],
  },
  {
    id: 'general-user-auth',
    path: '/admin/general/user/auths',
    label: 'Authorisation',
    subLabel: '',
    icon: TopicIcon,
    color: '',
    autorizations: ['GENERAL_USER_AUTHORIZATION'],
  },
];

import { lazy } from 'react';
import { RoutesDefinition } from '../routes';
import { MENU_GENERAL_LIST } from '../Lists/general-menu-list';
import { MENU_GENERAL_USER_LIST } from '../Lists/general-user-menu-list';

const generalMainRoutes: RoutesDefinition[] = [
  {
    name: 'Paramétrage Général',
    component: lazy(() => import('../../components/AdminInterface/General')),
    authorization: 'GENERAL_USER',
    path: '/admin/general/user',
    isLeftDrawer: false,
    isDashBoardCards: true,
    menuList: MENU_GENERAL_LIST,
  },
];

const generalUserRoutes: RoutesDefinition[] = [
  {
    name: 'Utilisateurs',
    component: lazy(() => import('../../components/AdminInterface/General/Users')),
    authorization: 'GENERAL_USER_USERS',
    path: '/admin/general/user/users',
    isLeftDrawer: true,
    menuList: MENU_GENERAL_USER_LIST,
  },
  {
    name: 'Rôles',
    component: lazy(() => import('../../components/AdminInterface/General/Roles')),
    authorization: 'GENERAL_USER_ROLES',
    path: '/admin/general/user/roles',
    isLeftDrawer: true,
    menuList: MENU_GENERAL_USER_LIST,
  },
  {
    name: 'Authorisations',
    component: lazy(() => import('../../components/AdminInterface/General/Auths')),
    authorization: 'GENERAL_USER_AUTHS',
    path: '/admin/general/user/auths',
    isLeftDrawer: true,
    menuList: MENU_GENERAL_USER_LIST,
  },
];

const generalRoutes = [
  ...generalMainRoutes,
  ...generalUserRoutes,

];

export default generalRoutes;

import {
  Tooltip, IconButton, Menu, MenuItem, Typography,
  Avatar,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../hook/UseReduxHook';
import { cleanUser } from '../../../store/user/user.slice';
import { stringToColor, initialsCalculation } from '../utils';
import { RootState } from '../../../store';

const CustomAvatar:React.FC = () => {
  const user = useSelector((state: RootState) => state.user.current);
  const dispatch = useAppDispatch();

  const settings = ['Se Déconnecter'];
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleMenuAction = (setting: any) => {
    switch (setting) {
      case 'Se Déconnecter':
        dispatch(cleanUser());
        break;
      default:
    }
  };

  const stringAvatar = (name: string) => ({
    sx: {
      bgcolor: stringToColor(name),
      fontSize: '1rem',
      width: 'calc(0.8*64px)',
      height: 'calc(0.8*64px)',
    },
    children: initialsCalculation(name),
  });

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar {...stringAvatar(`${user.name} ${user.surname}`)} />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {settings.map((setting) => (
          <MenuItem key={setting} onClick={() => handleMenuAction(setting)}>
            <Typography textAlign="center">{setting}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default CustomAvatar;

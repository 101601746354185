/* eslint-disable import/named */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { UIState } from './ui';

const initialState: UIState = {
  snackbarMessage: '',
  snackbarColor: 'success',
  snackbarTimeout: 3600,
  snackbarDisplay: false,
  isLoading: false,
  isOpenModal: false,
};
export const UiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    displayLoader(state: UIState, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    displayAlert(state: UIState, action) {
      state.snackbarMessage = action.payload.message;
      state.snackbarColor = action.payload.color;
      state.snackbarTimeout = action.payload.timeout;
      state.snackbarDisplay = true;
    },
    closeAlert(state: UIState) {
      state.snackbarMessage = '';
      state.snackbarColor = 'success';
      state.snackbarTimeout = 3000;
      state.snackbarDisplay = false;
    },
    displayModal(state: UIState, action) {
      state.isOpenModal = action.payload;
    },
  },
});

export const UiSliceReducer = {
  ...UiSlice.actions,
};
